<template>
  <div class="fast">
    <div class="fastBox">
      <div class="title">
        <p>{{date[2]}}/</p>
        <p>
          <i>{{date[1]}}</i>
          <i>{{date[0]}}</i>
        </p>
      </div>
      <div class="loadingBox">
        <img class="loading" v-if="loading == true" src="../../../static/common/loading.png" alt="">
      </div>
      <div class="content">
        <div class="contentBox" v-for="item in fastContent" :key="item.id">
          <span>{{item.time}}</span>
          <div>
            <i style="color:#e90013;font-size: 26px;" class="iconfont icon-tongzhi"></i>
            <li></li>
          </div>
          <p>{{item.context}}</p>
        </div>
        <button @click="moreFast">
          <span>更多</span>
          <img src="../../../static/market/more.png" alt="">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import {fastList} from '../../../api/info'
export default {
  name:'fast',
  data(){
    return{
      date:[],
      fastContent:[],
      // 接口参数
      attr:{
        pageIndex:0,
        pageSize:5,
      },
      loading:true,
    }
  },
  created(){
    // 获取当天日期并用'-'进行分割；
    this.date = ((new Date()).Format('yyyy-MM-dd')).split('-');
    this.init(this.attr,0);
  },
  methods:{
    // 初始化数据
    init(attr,key){
      fastList(attr).then(res=>{
        if(res.ok == 1){
          if(key == 1){
            this.fastContent = [...this.fastContent,...res.data];
          }else{
            this.fastContent = res.data;
            this.loading = false;
          }
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    // 获取更多评论 
    moreFast(){
      if(this.attr.pageSize == 5){
        this.attr.pageIndex = 1;
        this.attr.pageSize = 10;
        this.init(this.attr,0);
      }else{
        this.attr.pageIndex++;
        this.init(this.attr,1);
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .fast{
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .fastBox{
      width: 100%;
      .loadingBox{
        width: 100%;
        text-align: center;
        .loading{
          width: 50px;
        }
      }
      .title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding-left: 155px;
        p:nth-child(1){
          font-size: 44px;
          font-weight: 500;
        }
        p:nth-child(2){
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-weight: 500;
        }
      }
      .content{
        width: 100%;
        margin: 55px 0 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .contentBox{
          width: 100%;
          height: auto;
          height: auto;
          display: flex;
          span{
            color: #333333;
            margin-top: 5px;
          }
          div{
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 55px 0 45px;
            li{
              list-style: none;
              width: 2px;
              height: 100%;
              background-color: #e90013;
            }
          }
          p{
            width: 70%;
            margin-bottom: 30px;
            background-color: #fafafa;
            box-sizing: border-box;
            padding: 25px 50px 25px 25px;
          }
        }
        .contentBox:last-of-type{
          p{
            margin-bottom: 0;
          }
        }
        button{
          width: 195px;
          height: 60px;
          border-radius: 5px;
          background-color: #db3d4c;
          border: none;
          margin: 72px auto 110px; 
          cursor: pointer;
          span{
            color: #ffffff;
            margin-right: 5px;
          }
          img{
            width: 10px;
            height: auto;
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .fast{
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .fastBox{
        .title{
          padding-left: 55px;
          p:nth-child(1){
            font-size: 26px;
          }
          p:nth-child(2){
            i{
              font-size: 14px;
            }
          }
        }
        .loadingBox{
          width: 100%;
          text-align: center;
          .loading{
            width: 30px;
          }
        }
        .content{
          margin: 25px 0 20px;
          .contentBox{
            span{
              font-size: 13px;
              text-align: center;
            }
            div{
              margin: 0 20px 0 0;
              i{
                font-size: 18px !important;
              }
              li{
                width: 1px;
              }
            }
            p{
              width: 90%;
              margin-bottom: 20px;
              padding: 15px 20px 15px 15px;
              font-size: 14px;
              text-align: justify;
            }
          }
          .contentBox:last-of-type{
            p{
              margin-bottom: 0px;
            }
          }
          button{
            width: 125px;
            height: 40px;
            border-radius: 5px;
            background-color: #db3d4c;
            border: none;
            cursor: pointer;
            color: #ffffff;
            margin: 20px 0;
            font-size: 14px;
          }
        }
      }
    }
  }
</style>